var _ = require('underscore');
const { hasPaidCreativeCloud } = require('../../../util/user_info.js');
const { FAVORITE_EVENT_NAME } = require('@adobe-fonts/site/events/FavoriteEvent.js');

var DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT = 36;
var SIMILARITY_FEEDBACK_KEY = 'similarity';

const SaveToLibraryMenuLoadedEvent = require('@adobe-fonts/site/events/SaveToLibraryMenuLoadedEvent.js');

const registerFavoriteEventHandlers = require('../../../components/mixins/variation-card/VariationCardFavorite.js').registerEventHandlers;
const registerSaveToLibraryEventHandlers = require('../../../components/mixins/SaveToLibrary.js').registerEventHandlers;
const LibrariesCache = require('../../../util/libraries_cache.js').default;
const { toLibraryElement } = require('../../../util/libraries.js').default;

/**
 * Controller for a directive that can be used to render a font variation.
 * @ngInject
 */
function FontVariationCardDirectiveController($element,
                                              $q,
                                              $scope,
                                              $window,
                                              AddFontsToWebProjectService,
                                              AuthenticationService,
                                              DataService,
                                              DialogService,
                                              FeedbackService,
                                              FirstMileService,
                                              I18nService,
                                              NewrelicService,
                                              NotificationService,
                                              SyncService) {
  var self = this;

  self.$q = $q;
  self.$scope = $scope;
  self.$window = $window;

  self.AddFontsToWebProjectService = AddFontsToWebProjectService;
  self.AuthenticationService = AuthenticationService;
  self.DataService = DataService;
  self.DialogService = DialogService;
  self.FeedbackService = FeedbackService;
  self.FirstMileService = FirstMileService;
  self.I18nService = I18nService;
  self.NewrelicService = NewrelicService;
  self.NotificationService = NotificationService;
  self.SyncService = SyncService;

  registerFavoriteEventHandlers($element[0]);
  registerSaveToLibraryEventHandlers($element[0]);

  self.DataService.get('/neue/i18n').then(function (i18n) {
    self.i18n = i18n;
  });

  self.$window.addEventListener(SaveToLibraryMenuLoadedEvent.EVENT_NAME, function(event) {
    LibrariesCache.setLibraries(event.detail.libraries);
  });

  self.$window.addEventListener(FAVORITE_EVENT_NAME, self.handleFavoriteEvent.bind(self));
}

/**
 * Returns the current value of the example text for the card.
 *
 * @return {String}
 */
FontVariationCardDirectiveController.prototype.getExampleText = function () {
  var exampleText = this.exampleText();
  if (exampleText && exampleText.value) {
    return exampleText.value;
  } else {
    var variation = this.variation();
    var textSample = this.textSamples()[variation.default_language];

    return textSample.kana_only ? textSample.list_kana : textSample.list;
  }
};

/**
 * Returns the current styling for the example text.
 *
 * @return {
 *   fontSize: {String} A font size in pixels
 * }
 */
FontVariationCardDirectiveController.prototype.getExampleTextStyle = function () {
  var exampleText = this.exampleText();
  return {
    fontSize: (exampleText && exampleText.size && !this.isSimilarCard ? exampleText.size : DEFAULT_FONT_SIZE_FOR_EXAMPLE_TEXT) + 'px'
  };
};

/**
 * Returns an object that will be used to render the family card's font on the
 * page using the display-in-font directive.
 *
 * @returns {{
 *   cssName: string,
 *   fvd: string,
 *   isDynamic: boolean,
 *   opaqueId: string,
 *   unicodeRange: string,
 *   featureSettings: string
 *   kanaOnly: boolean
 * }}
 */
FontVariationCardDirectiveController.prototype.getFontDisplayData = function() {
  var variation = this.variation();
  var textSamples = this.textSamples();

  var unicodeRange = textSamples[variation.default_language].unicode_range;
  var featureSettings = textSamples[variation.default_language].feature_settings;
  var kanaOnly = textSamples[variation.default_language].kana_only;

  if (!kanaOnly) {
    kanaOnly = false;
  }

  return {
    cssName: variation.family.web_id + '-' + variation.font.web.fvd,
    fvd: variation.font.web.fvd,
    isDynamic: true,
    opaqueId: variation.family.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    kanaOnly: kanaOnly
  };
};

/**
 * Return the fields are documented on https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=ccexperience&title=Font,
 * along with any additional fields needed for the component to render.
 *
 * @return {Array}
 */
FontVariationCardDirectiveController.prototype.getLibraryElements = function() {
  const font = this.variation();
  return [toLibraryElement(font, this.textSamples())];
};

/**
 * Returns the current class for the variation card
 * @returns String
 */
FontVariationCardDirectiveController.prototype.getTextDirectionClass = function() {
  if (this.variation().rtl) {
    return 'sample-right';
  }

  return '';
};

/**
 * Handles click on the code button.
 */
FontVariationCardDirectiveController.prototype.handleCodeButtonClick = function() {
  this.AddFontsToWebProjectService.addFamilyToWebProjectWithAuthentication(
    this.variation().family.slug,
    this.variation().slug);
};

FontVariationCardDirectiveController.prototype.handleCodeButtonKeydown = function(event) {
  if (event.key !== 'Enter'){
    return;
  }
  this.handleCodeButtonClick();
};

FontVariationCardDirectiveController.prototype.handleFavoriteEvent = function(event) {
  if ((event.detail || {}).slug === this.variation().family.slug) {
    this.variation().favorite = (event.detail || {}).favorite;
  }
};

/**
 * This ensures that the heart icon is toggled in both list and grid view
 *
 */
FontVariationCardDirectiveController.prototype.toggleFavorite = function() {
  this.variation().favorite = !this.variation().favorite;
  this.$window.dispatchEvent(new CustomEvent(FAVORITE_EVENT_NAME, { detail: { fontVariationId: this.variation().id, favorite: this.variation().favorite } }));
};

/**
 * Returns the default value of the example text for family.
 *
 * @return {String}
 */
FontVariationCardDirectiveController.prototype.getDefaultExampleText = function() {
  var exampleText = this.textSamples()[this.variation().default_language || 'en'];
  return exampleText.kana_only ? exampleText.list_kana : exampleText.list;
};

/**
 * Determines if a link to the variation's family detail page should be shown
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowFamilyLink = function() {
  return this.linkToFamily !== undefined;
};

/**
 * Determines if a link to the variation's family detail page should be shown on font similarity
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowSimilarVariationLink = function() {
  return this.linkToSimilarVariation !== undefined;
};

/**
 * Handles clicking on the sync button.
 */
FontVariationCardDirectiveController.prototype.handleSyncButtonClick = function() {
  this._handleFontSync();
};

/**
 * Handles clicking on the add/remove family button when use model v2 flag is enabled.
 */
FontVariationCardDirectiveController.prototype.handleUseModelSyncButtonClick = function() {
  this._handleFontSync(true);
};
/**
 * Is this variation available for the user to sync?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.isAvailableForSync = function() {
  if (this.userLoggedIn()) {
    return 'sync' in this.variation().font && this.variation().font.sync.required_action.length === 0;
  } else {
    return this.isUsableForSync();
  }
};

/**
 * Is this variation available for web use?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.isAvailableForWeb = function() {
  if (this.userLoggedIn()) {
    return 'web' in this.variation().font && this.variation().font.web.required_action.length === 0;
  } else {
    return this.isUsableForWeb();
  }
};

FontVariationCardDirectiveController.prototype.cardActionClass = function() {
  if (this.isAvailableForWeb()) {
    return 'with-web-license';
  } else {
    return 'without-web-license';
  }
};

/**
 * Has this variation been purchased by the user?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.isPurchased = function() {
  return this.variation().marketplace && this.variation().marketplace.is_purchased_by_user;
};

/**
 * Has this variation been purchased and synced by the user?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.isPurchasedAndSynced = function() {
  return this.isPurchased() && this.isVariationSelectedForSync();
};

/**
 * Is this variation synced via subscription?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.isSyncedViaSubscription = function() {
  return this.isVariationSelectedForSync() && !this.isPurchased();
};

FontVariationCardDirectiveController.prototype.isUsableForWeb = function() {
  return 'web' in this.variation().font &&
    (this.variation().font.web.library_availability.trial || this.variation().font.web.library_availability.full);
};

FontVariationCardDirectiveController.prototype.isUsableForSync = function() {
  return 'sync' in this.variation().font &&
    (this.variation().font.sync.library_availability.trial || this.variation().font.sync.library_availability.full);
};

/**
 * Is this variation selected for sync?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.isVariationSelectedForSync = function() {
  var font = this.variation().font;
  if (font && font.sync) {
    return font.sync.is_selected || (!!font.sync.install_state && _.contains(font.sync.install_state.split(','), 'cc'));
  }

  return false;
};

/**
 * Returns true if using the font for sync ONLY requires the given action for entitlement. i.e. No other actions can be
 * performed to gain entitlement to the font's sync use.
 *
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.syncOnlyRequiresAction = function(action) {
  var syncRequirements = ((this.variation().font || {}).sync || {}).required_action;

  // The font has sync requirements (i.e. desktop/sync use is available) AND the actions required before being able to
  // sync the font include the given action AND there are no other actions that can be taken in order to gain an entitlement.
  //    i.e. The given action is the only option for resolving an entitlement to the font's desktop use.
  var syncOnlyRequiresAction = !!syncRequirements && _.contains(syncRequirements, action) && _.isEmpty(_.without(syncRequirements, action));

  // Return true if syncing the font only requires the given action AND the font is not available for web.
  return syncOnlyRequiresAction && !this.isAvailableForWeb();
};

/**
 * Does this variation require the user to purchase before using the variation?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.requiresPurchase = function(fontType) {
  if (fontType in this.variation().font) {
    return this.variation().font[fontType].required_action.indexOf('purchase') >= 0;
  }

  return false;
};

/**
 * Does this variation require the user to upgrade before using the variation?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.requiresUpgrade = function(fontType) {
  if (fontType in this.variation().font) {
    return this.variation().font[fontType].required_action.indexOf('upgrade') >= 0;
  }

  return false;
};

FontVariationCardDirectiveController.prototype.getFlaggedClass = function () {
  return this.hasNegativeFeedback() ? 'flagged' : '';
};

/**
 * Should we show the web projects button?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowWebProjectsButton = function () {
  return this.isAvailableForWeb() && !this.isSimilarCard;
};

/**
 * Should we show the sync button for this variation?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowSyncButton = function() {
  return this.isAvailableForSync() && !this.isVariationSelectedForSync();
};

/**
 * Should we show the unsync button for this variation?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowUnsyncButton = function() {
  return this.isSyncedViaSubscription();
};

/**
 * Should we show the purchase button for this variation?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowPurchaseButton = function() {
  return this.syncOnlyRequiresAction('purchase') || (this.requiresPurchase('sync') && this.showRequiredAction === 'purchase');
};

/**
 * Should we show the webOnly text for this variation?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowWebOnly = function() {
  return !this.shouldShowPurchaseButton() && !this.shouldShowUpgradeButton() && !this.isAvailableForSync() && this.isAvailableForWeb();
};

/**
 * Should we show the webOnly requires an upgrade text for this variation?
 * @returns {Boolean}
 */
FontVariationCardDirectiveController.prototype.shouldShowWebOnlyRequiresUpgrade = function() {
  return !this.shouldShowPurchaseButton() && !this.shouldShowUpgradeButton() && !this.isUsableForSync() && !this.isAvailableForWeb() && this.isUsableForWeb();
};

FontVariationCardDirectiveController.prototype.shouldShowAdminOnly = function() {
  return !this.isUsableForSync() && !this.isUsableForWeb();
};

FontVariationCardDirectiveController.prototype.shouldShowNotAvailable = function() {
  return !this.shouldShowPurchaseButton() && !this.isAvailableForWeb() &&
    !this.isAvailableForSync() && !this.shouldShowUpgradeButton();
};

FontVariationCardDirectiveController.prototype.shouldShowUpgradeButton = function() {
  if (hasPaidCreativeCloud()) {
    return false;
  }

  return this.syncOnlyRequiresAction('upgrade') || (this.requiresUpgrade('sync') && this.showRequiredAction === 'upgrade');
};

FontVariationCardDirectiveController.prototype.shouldShowReinstallComponent = function() {
  if (this.isSimilarCard) {
    return false;
  }

  var font = this.variation().font;
  if (font && font.sync) {
    return !!font.sync.install_state && _.contains(font.sync.install_state.split(','), 'cc');
  }

  return false;
};

FontVariationCardDirectiveController.prototype.isMouseOverReinstallIcon = function() {
  return this.variation().isMouseOverReinstallIcon;
};

FontVariationCardDirectiveController.prototype.handleReinstallIconMouseOver = function() {
  this.variation().isMouseOverReinstallIcon = true;
};

FontVariationCardDirectiveController.prototype.handleReinstallIconMouseLeave = function() {
  this.variation().isMouseOverReinstallIcon = false;
};

FontVariationCardDirectiveController.prototype.handleFontVariationCardMouseOver = function() {
  this.variation().isMouseOverFontVariationCard = true;
};

FontVariationCardDirectiveController.prototype.handleFontVariationCardMouseLeave = function() {
  this.variation().isMouseOverFontVariationCard = false;
};

FontVariationCardDirectiveController.prototype.shouldShowSimilarFontsAndPairingsLink = function() {
  return this.variation().isMouseOverFontVariationCard && this._hasFontRecommendations();
};

FontVariationCardDirectiveController.prototype.shouldShowFlagResultIcon = function() {
  return (this.variation().isMouseOverFontVariationCard || this.selectedFeedback === 'negative') &&
          this.isSimilarCard && this.userLoggedIn();
};

FontVariationCardDirectiveController.prototype.hasNegativeFeedback = function() {
  return this.isSimilarCard && this.selectedFeedback === 'negative';
};

FontVariationCardDirectiveController.prototype.handleFontVariationCardClick = function() {
  this._recordEvent('recommended_font_card_click', 'positive');
};

FontVariationCardDirectiveController.prototype.shouldShowOpenFontSpecimenInCCXLink = function () {
  // Don't show open in for similarity card or if font is a variable font
  if (this.isSimilarCard || this.variation().is_variable_font) {
    return false;
  }

  return this.variation().isMouseOverFontVariationCard;
};

/**
 * @private
 */
FontVariationCardDirectiveController.prototype._hasFontRecommendations = function() {
  return this.variation().has_similar_fonts_or_pairings && this.variation().default_language === 'en';
};

/**
 * Adds the variation to the user's synced variations and sets the state for the
 * sync button.
 * @private
 */
FontVariationCardDirectiveController.prototype._addSyncedVariation = function(isUseModelEnabled = false) {
  var self = this;
  self.variation().isSyncing = true;
  var previousInstallState = self.variation().font.sync.install_state;

  self.SyncService.addSyncedVariations([self.variation().id]).then(function(response) {
    self.variation().isSyncing = false;

    if (self._isSyncSuccessResponse(response)) {
      if (isUseModelEnabled) {
        self._handleVariationUseModelSyncSuccess(self.variation().font.sync.font_id);
      } else {
        self._handleVariationSyncSuccess(previousInstallState);
      }
    } else if ('data' in response) {
      return self.$q.reject(response.data);
    } else {
      return self.$q.reject();
    }
  }).catch(function(err) {
    self.variation().isSyncing = false;
    if (err && err.message) {
      self.NotificationService.error(err.message);
    } else if (isUseModelEnabled) {
      self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_adding_fonts'], {
        actionLabel: self.i18n['neue.browse.family_card']['try_again'],
        callbackAction: function() { self._addSyncedVariation(isUseModelEnabled); }});
    } else {
      self.NotificationService.error(self.i18n['neue.browse.family_card']['error_occurred_activating_fonts']);
    }
  });
};

/**
 * Handles syncing the a font.
 * @private
 */
FontVariationCardDirectiveController.prototype._handleFontSync = function(isUseModelEnabled = false) {
  var self = this;
  this.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_font', {font_variation_id: this.variation().id}).then(function() {
      if (self.isVariationSelectedForSync()) {
        if (self.isSimilarCard) {
          self._recordEvent('deactivate-button-click', 'negative', isUseModelEnabled);
        }
        self._handleRemoveSyncedVariation(isUseModelEnabled);
      } else {
        if (self.isSimilarCard) {
          self._recordEvent('activate-button-click', 'positive', isUseModelEnabled);
        }
        self._addSyncedVariation(isUseModelEnabled);
      }
    });
};

FontVariationCardDirectiveController.prototype._recordEvent = function(eventName, feedback, isUseModelEnabled = false) {
  var self = this;
  if (self.isSimilarCard) {
    self.NewrelicService.addPageAction('typekit.browse.similarity_and_pairing.feedback', {
      category: SIMILARITY_FEEDBACK_KEY,
      subcategory: eventName,
      feedback: feedback,
      variation_one: self.variation().name,
      variation_two: self.originalVariation().name,
      useModelEnabled: isUseModelEnabled
    });
    var id = self.variation().id + '-' + self.originalVariation().id;
    self.FeedbackService.post(SIMILARITY_FEEDBACK_KEY, eventName, id, feedback, null, self.variation().id, self.originalVariation().id);
  }
};

FontVariationCardDirectiveController.prototype.reinstall = function() {
  var self = this;
  this.AuthenticationService.handleAuthenticationWithPostLoginAction(
    'activate_font', {font_variation_id: this.variation().id}).then(function() {
      self.NewrelicService.addPageAction('typekit.family-page.click.reinstall-button', { variationId: self.variation().id });
      self._addSyncedVariation();
    });
};

/**
 * Handles a successful variation sync when use model v2 is enabled.
 * @private
 */
FontVariationCardDirectiveController.prototype._handleVariationUseModelSyncSuccess = function(tkFontId) {
  var self = this;
  self.variation().font.sync.install_state = 'cc';
  if (self.onActivate()) {
    self.$scope.$eval(self.onActivate());
  }

  self.FirstMileService.onUseModelFontActivation(self.i18n, {queryParams: {tkFontId}});
};

/**
 * Handles a successful variation sync.
 * @private
 */
FontVariationCardDirectiveController.prototype._handleVariationSyncSuccess = function(previousInstallState) {
  var self = this;
  self.variation().font.sync.is_selected = true;
  self.variation().font.sync.install_state = 'os';
  if (previousInstallState === 'cc') {
    return;
  }

  var message = self.I18nService.getMessageWithCount(self.i18n['neue.browse.family_card']['fonts_named_activated_action'], 1, {
    font: self.variation().name,
    count: 1
  });
  self.NotificationService.success(message);

  if (self.onActivate()) {
    self.$scope.$eval(self.onActivate());
  }

  self.FirstMileService.shouldShowFontActivationDialog().then(function(shouldShow) {
    if (shouldShow) {
      return self.FirstMileService.showFontActivationDialog();
    }
  });
};

/**
 * Checks the response data and returns true if it was a successful response.
 *
 * @param {Object} response
 * @return {Boolean}
 */
FontVariationCardDirectiveController.prototype._isSyncSuccessResponse = function(response) {
  return 'data' in response &&
    response.data['added_font_ids'].indexOf(this.variation().id) !== -1;
};

/**
 * Removes the variation from the user's synced variations after user confirms it in the modal dialog and sets the state
 * for the sync button.
 * @private
 */
FontVariationCardDirectiveController.prototype._handleRemoveSyncedVariation = function(isUseModelEnabled = false) {
  var self = this;
  if (!isUseModelEnabled) {
    self._removeSyncedVariation();
  } else {
    self.FirstMileService.shouldShowRemoveFamilyConfirmationDialog().then(function(shouldShow) {
      if (shouldShow) {
        var header = self.i18n['neue.browse.family_card']['remove_font_confirmation_header'];
        var description = self.i18n['neue.browse.family_card']['remove_font_confirmation_description'];
        return self.FirstMileService.showRemoveFamilyConfirmationDialog(header, description, function() {
          self._removeSyncedVariation(true);
        });
      } else {
        self._removeSyncedVariation(true);
      }
    });
  }
};

/**
 * Removes the variation from the user's synced variations and sets the state
 * for the sync button.
 * @private
 */
FontVariationCardDirectiveController.prototype._removeSyncedVariation = function(isUseModelEnabled = false) {
  var self = this;
  self.NewrelicService.addPageAction('typekit.click.unsync-button', { isUseModelEnabled: isUseModelEnabled });
  self.variation().isSyncing = true;

  self.SyncService.removeSyncedVariations([self.variation().id]).then(function() {
    self.variation().isSyncing = false;
    self.variation().font.sync.is_selected = false;
    self.variation().font.sync.install_state = '';
    if (self.onDeactivate()) {
      self.$scope.$eval(self.onDeactivate());
    }
    if (isUseModelEnabled) {
      self.NotificationService.notice(self.i18n['neue.browse.family_card']['single_font_removed_success_message']);
    } else {
      var message = self.I18nService.getMessageWithCount(self.i18n['neue.browse.family_card']['fonts_named_deactivated_action'], 1, {
        font: self.variation().name,
        count: 1
      });
      self.NotificationService.success(message);
    }
  }, function() {
    self.variation().isSyncing = false;
  });
};


module.exports = FontVariationCardDirectiveController;
